import React from 'react'

import { Image, Stack, Text } from '@procsea/design-system'

import klarysLogo from 'src/images/klarys-logo-menu.png'

import { LandingPageContainer } from './GcpLandingPage.styles'
import GcpLandingPageForm from './GcpLandingPageForm'

const GcpLandingPage = () => (
  <LandingPageContainer data-e2e="registration-landing-page">
    <Image alt="klarys-logo" data-e2e="klarys-logo" src={klarysLogo} width="300px" />

    <Stack alignment="center" spacing="xlarge">
      <Text size="xlarge" bold variant="secondary">
        {gettext('Welcome to Klarys!')}
      </Text>

      <Stack alignment="center" spacing="small">
        <Text size="large" variant="secondary">
          {gettext('Thank you for embarking on this journey with us!')}
        </Text>

        <Text size="large" variant="secondary">
          {gettext(
            'Please register your personal information and we will contact you shortly afterwards.'
          )}
        </Text>
      </Stack>

      <GcpLandingPageForm />
    </Stack>
  </LandingPageContainer>
)

export default GcpLandingPage
