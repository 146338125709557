import { GenerateQueriesArgs, generateQueries } from 'src/queries/queryGenerator'

export const REGISTRATION_ENDPOINT = 'registration/external'
export const REGISTRATION_QUERY_KEY = 'registrationExternal'

export interface RegistrationPayload {
  companyName: string
  email: string
  firstName: string
  lastName: string
  queryParams: string
}

const config: GenerateQueriesArgs<{}, {}, RegistrationPayload> = {
  endpoint: REGISTRATION_ENDPOINT,
  queryKey: REGISTRATION_QUERY_KEY,
}

export const { usePostEntity: usePostRegistration } = generateQueries(config)
