import React, { useEffect } from 'react'
import { SetValueConfig, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { Button, FormOnChange, Inline, Input, Stack } from '@procsea/design-system'

import { dispatchCreateNotification } from 'src/action-creators/notifications'
import { isErrorResponse } from 'src/action-creators/types'
import { usePostRegistration } from 'src/queries/public/registration/registration'
import { requiredRule } from 'src/utils/form/validationRules.utils'

import { FormCard } from './GcpLandingPage.styles'
import { GcpRegsitrationField, GcpRegsitrationFormValue } from './GcpLandingPageForm.types'

export const shouldValidateAndDirtyConfig: SetValueConfig = {
  shouldDirty: true,
  shouldValidate: true,
}

const GcpLandingPageForm = () => {
  const form = useForm<GcpRegsitrationFormValue>()

  const { companyName, email, firstName, lastName } = useWatch<GcpRegsitrationFormValue>({
    control: form.control,
  })

  const location = useLocation()

  const dispatch = useDispatch()

  const { mutate: postRegistration, isLoading } = usePostRegistration()

  useEffect(() => {
    form.register(GcpRegsitrationField.COMPANY_NAME, requiredRule)
    form.register(GcpRegsitrationField.EMAIL, requiredRule)
    form.register(GcpRegsitrationField.FIRST_NAME, requiredRule)
    form.register(GcpRegsitrationField.LAST_NAME, requiredRule)
  }, [])

  const handleChange: FormOnChange<string> = ({ name, value }) => {
    form.setValue(name as GcpRegsitrationField, value, shouldValidateAndDirtyConfig)
  }

  const handleValid: SubmitHandler<GcpRegsitrationFormValue> = value => {
    postRegistration(
      { ...value, queryParams: location.search },
      {
        onSuccess: () => {
          dispatchCreateNotification(dispatch)({
            message: gettext('You have successfully registered!'),
            type: 'positive',
          })
          form.reset()
        },
        onError: err => {
          dispatchCreateNotification(dispatch)({
            message: isErrorResponse(err)
              ? err.userMessage
              : gettext('An unexpected error occurred while trying to register your information.'),
            type: 'negative',
          })
        },
      }
    )
  }

  return (
    <FormCard shadow="middle" spacing="large">
      <form data-e2e="registration-form" onSubmit={form.handleSubmit(handleValid)}>
        <Stack alignment="center" spacing="xlarge">
          <Stack alignment="center" spacing="medium">
            <Inline alignment="center" spacing="medium">
              <Input
                data-e2e="registration-form-first-name-field"
                errorMessage={form.errors.firstName?.message}
                label={gettext('First name')}
                name={GcpRegsitrationField.FIRST_NAME}
                onChange={handleChange}
                required
                value={firstName}
              />

              <Input
                data-e2e="registration-form-last-name-field"
                errorMessage={form.errors.lastName?.message}
                label={gettext('Last name')}
                name={GcpRegsitrationField.LAST_NAME}
                onChange={handleChange}
                required
                value={lastName}
              />
            </Inline>

            <Input
              data-e2e="registration-form-email-field"
              errorMessage={form.errors.email?.message}
              label={gettext('Email')}
              name={GcpRegsitrationField.EMAIL}
              onChange={handleChange}
              type="email"
              required
              width="100%"
              value={email}
            />

            <Input
              data-e2e="registration-form-company-name-field"
              errorMessage={form.errors.companyName?.message}
              label={gettext('Company name')}
              name={GcpRegsitrationField.COMPANY_NAME}
              onChange={handleChange}
              required
              width="100%"
              value={companyName}
            />
          </Stack>

          <Button
            data-e2e="registration-form-submit-button"
            disabled={isLoading}
            fluid
            type="submit"
            variant="primary"
          >
            {gettext('Register')}
          </Button>
        </Stack>
      </form>
    </FormCard>
  )
}

export default GcpLandingPageForm
