import React from 'react'

import { Width } from '../../../themes'
import { ImageContainer } from './Image.styles'

export interface ImageProps {
  'data-e2e'?: string
  alt?: string
  src: string
  width: Width
}

const Image = ({ 'data-e2e': dataE2e = 'image', alt, src, width }: ImageProps) => (
  <ImageContainer width={width}>
    <img data-e2e={dataE2e} alt={alt} src={src} />
  </ImageContainer>
)

export default Image
