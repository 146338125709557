export enum GcpRegsitrationField {
  COMPANY_NAME = 'companyName',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export interface GcpRegsitrationFormValue {
  [GcpRegsitrationField.COMPANY_NAME]: string
  [GcpRegsitrationField.EMAIL]: string
  [GcpRegsitrationField.FIRST_NAME]: string
  [GcpRegsitrationField.LAST_NAME]: string
}
